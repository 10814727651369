@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


:root {
  --gray0: #f8f8f8;
  --gray1: #dbe1e8;
  --gray2: #b2becd;
  --gray3: #6c7983;
  --gray4: #454e56;
  --gray5: #2a2e35;
  --gray6: #12181b;
  --blue: #0084a5;
  --purple: #a82dd1;
  --yellow: #fff565;
  --theme-color: #1890ff;
  --deep-blue: #001529;
  --light-gray: #6c798370;
  --low-opacity-deep-blue: rgba(0, 21, 41, 0.85);
  --low-opacity-gray: rgba(248, 248, 248, 0.85);

}

.lightTheme {
  --bg: var(--gray0);
  --bg-nav: linear-gradient(to right, var(--gray1), var(--gray3));
  --bg-dropdown: var(--gray1);
  --text: var(--gray6);
  --border-color: var(--blue);
  --bg-solar: var(--yellow);
  --social-icon-border: none;
  --box-shadow: var(--light-gray);
  --bg-mobile-nav: var(--low-opacity-gray);
}

.darkTheme {
    --bg: var(--deep-blue);
    --bg-nav: linear-gradient(to right, var(--deep-blue), var(--gray6));
    --bg-dropdown: var(--gray4);
    --text: var(--gray0);
    --border-color: var(--purple);
    --bg-solar: var(--blue);
    --social-icon-border: var(--gray0);
    --bg-mobile-nav: var(--low-opacity-deep-blue);

}

html, body{
  overflow-x: hidden;
  color: var(--text) !important;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  font-size: x-large;
  height: unset !important;
}

p{
  font-size: 16px;
}

h1{
  color: var(--text) !important;
}

.text-align-left{
  text-align: left !important;
}

.text-align-center{
  text-align: center !important;
}

.text-bold{
  font-weight: bold !important;
}

.ant-divider-horizontal{
  &:before, &:after{
    border-top: 1px solid var(--theme-color) !important;
  }
}

.big-heading {
 font-size: clamp(30px, 8vw, 80px) !important;
 margin: 0 !important;
}

.text-align-center{
  text-align: center !important;
}

.h-100{
  height: 100% !important;
}