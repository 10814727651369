@import '~antd/dist/antd.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';


.app-header {
  padding: 0;
  position: fixed;
  z-index: 5;
  width: 100%;
}

.d-block{
  display: block !important;
}

.justify-center{
  justify-content: center !important;
}

.justify-start{
  justify-content: start !important;
}

.full-height-column{
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-height-row{
  min-height: calc(100vh - 64px);
  background-color: var(--bg) !important;
  padding-bottom: 20px;
  align-items: center;
}

footer{
  background: var(--bg) !important;
  color: var(--text) !important;
  transition: background 500ms ease-in-out, color 1000ms ease-in-out;
}
