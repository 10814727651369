
.contact-icon{
    margin-top: 24px;
    text-align: center;
    width: 120px !important;
    height: 120px !important;
    border: 1px solid var(--text);
    border-radius: 50%;
    line-height: 120px !important;
    border-color: var(--text);
}

.contact-card{
    box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.09);
    transition: .2s ease-in;
    margin-bottom: 1rem !important;
    background: var(--bg) !important;
    transition: background 500ms ease-in-out, color 1000ms ease-in-out, transform 200ms ease-in-out;
    text-align: center;
    p{
        padding: 0;
        margin: 0;
    }
    &:hover{
        transform: translate(0,-15px);
    }
}


.social-icon{
    text-align: center !important; 
    height: 65px !important;
    line-height: 75px !important;
    width: 65px !important;
    border-radius: 50% !important;
    transition: transform 200ms ease-in !important;
    padding: 0 !important;
    border: 1px solid var(--social-icon-border) !important;
    box-shadow: 1px 1px 5px var(--light-gray) !important;
    i{
        margin: 0 !important;
        padding: 0 !important;
    }
    &:hover{
        transform: scale(1.1);
    }
}

.contact-icon-col{
    margin-bottom: 1rem;
    display: flex !important;
    justify-content: center;
}

p, h1, h2, h3, h4, h5, h6, li, i{
    color: var(--text) !important
}