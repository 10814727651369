
.education-item{
    animation-duration: 0.8s; 
    animation-delay: 0.3s;
    animation-name: fadeInUp;
    box-shadow: 0px 10px 20px 4px #00000015;
    text-align: left;
}

.company-name{
    color: var(--theme-color);
}

.work-list{
    list-style-type: none;
    margin: 0;
    padding: 0;
    li{
        position: relative;
        padding-left: 30px;
        margin-bottom: 10px;
        &:before {
            content: "▹";
            position: absolute;
            left: 0px;
            color:var(--theme-color);
            font-size: 25px;
            
        }
    }
}

.image-container{
    max-width: 100%;
    img{
        height:auto;
        width: 100%;
    }
}

p, h1, h2, h3, h4, h5, h6, li{
    color: var(--text) !important
}