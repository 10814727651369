.hamburger{
    display: none;
    position: absolute;
    right: 4%;
    top: 40%;
    z-index: 10;
    .hamburger-line{
        width: 30px;
        height: 3px;
        background: var(--text);
        position: relative;
        transition: all .5s ease;
        display: flex;
        justify-content: center;
        &::before, &::after{
            content: " ";
            height: 3px;
            position: absolute;
            background: inherit;
            width: 100%;
        }
        &::before{
            top: -10px;
        }
        &::after{
            top: 10px
        }
    }
}

.navbar{
    text-align: center !important;
    background: var(--bg) !important;
    height: 100%;
    position: relative;
    .navbar-nav{
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0;
        padding: 0;
        height: 100%;
        .nav-item{
            height: 100%;
            margin: 0 .5rem;
            .nav-link{
                padding: 1rem;
                height: 100%;
                align-items: center;
                font-size: 14px;
                justify-content: center;
                display: flex;
                color: var(--text);
                text-decoration: none;
                transition: color .3s ease-in-out;
                &:hover, &.active{
                    background: var(--theme-color);
                    color: var(--gray0);
                }
            }
        }
    }
}

.light-circle{
    color: var(--gray0) !important;
    font-size: 1.5rem !important;
    margin-right: 10px;
}

.dark-circle{
    color: var(--deep-blue) !important;
    font-size: 1.5rem !important;
    margin-right: 10px;
}

@media only screen and (max-width: 600px){
    
    .navbar-nav{
        min-height: 100vh !important;
        width: 100% !important;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--bg-mobile-nav);
        z-index: 5;
        clip-path: circle(0px at 90% -10%);
        -webkit-clip-path: circle(0px at 90% -10%);
        transition: all 1s ease-out;

        .nav-item{
            height: unset !important;
            margin: .2rem !important;
            width: 40% !important;
            .nav-link{
                font-weight: bold;
                text-align: center;
            }
            &:hover, &.active{
                background: unset !important;
            }
        }

        &.open{
            clip-path: circle(800px at 90% -10%);
            -webkit-clip-path: circle(800px at 90% -10%);
        }
    }

    .hamburger{
        display: block;
        &.open{
            div{
                transform: rotate(135deg);
                &::before, &::after{
                    top: 0;
                    transform: rotate(90deg);
                }
            }
        }
    }
}